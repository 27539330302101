import logo from './logo.svg';
import './App.css';
import ResponsiveAppBar from './ResponsiveAppBar.js';
import badShib from './BAD3Dog.png';
import React from 'react';
import { useTypewriter } from 'react-simple-typewriter';
import Footer from './footer.js';
import './animatedButton.css';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';




function App() {
  const [typeEffect] = useTypewriter({
    words: [' crucial for aviation and shipping', ' essential for powering transportation', '  a driver of economic growth', ' an essential for making plastic'],
    loop: {},
    typeSpeed: 100,
    deleteSpeed: 40
  })
  return (
    <div className="App">
      <ResponsiveAppBar/>
      
      <div className="App-header">
        <div className="Intro">
          <div className="Shibacoin"><div className="shibShadow"><img className="bshib" src={badShib} alt='Bad Investment Coin Logo' /></div></div>
          <div className="typerDivDiv"><div className="typerDiv"> Oil is   
          <span>{typeEffect}</span>
           </div>
           </div>
        </div>
        <div className="founderDiv">
          <div className="founderTitle">
          <h1 className="BuyMerch">Explore Merch</h1>

          <Tooltip >
              <IconButton >
                  
                <div><a href="dontstopoil.com"className="animated-button1">
                <span></span>
  <span></span>
  <span></span>
  <span></span>
  <div className="AlphaFont">
  Shop
  </div>
                  </a></div>
              </IconButton>

            
              </Tooltip>
          </div>
        <div className="mockupImages"></div>
        </div>
        <div className="ShibaDown"> </div>
        
       
      </div>
      
      <Footer />

    </div>
  );
}

export default App;
